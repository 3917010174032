import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";

const Header = () => {
  const navItems = [
    {
      id: 1,
      name: "Home",
      url: "/",
    },
    {
      id: 2,
      name: "About",
      url: "/about",
    },
    {
      id: 3,
      name: "Service",
      url: "/services",
    },
    {
      id: 4,
      name: "Pricing",
      url: "/pricing",
    },
    {
      id: 5,
      name: "Blog",
      url: "/blogs",
    },
    {
      id: 6,
      name: "Contact",
      url: "/contact",
    },
  ];

  return (
    <>
      <Navbar className="border-bottom p-1" bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <Image
              src="/images/final-logo-dunkez 1.png"
              width={"197px"}
              height={"47px"}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="m-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {navItems.map((ele) => (
                <Link
                  to={ele.url}
                  key={ele.id}
                  className="text-decoration-none"
                >
                  <Nav className="px-3 text-dark" style={{ fontWeight: 500 }}>
                    {ele.name}
                  </Nav>
                </Link>
              ))}
            </Nav>
          </Navbar.Collapse>

          <Form className="d-md-flex d-none">
            <div className="d-align ps-3 bg-white shadow-sm">
              <BsSearch />
              <Form.Control
                type="search"
                style={{ borderRadius: "0", boxShadow: "none", width: "220px" }}
                className="me-2 border-0"
                placeholder="Search..."
                aria-label="Search"
              />
            </div>
          </Form>
          {/* <Button className={"border-0 btnColor"}>Free SEO Audit</Button> */}
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
